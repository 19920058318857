import React from "react"
import Layout from "../components/Layout"
import SpotlighFinal from "../constans/spotlights"
import BannerFinal from "../constans/dataMessanger"
import SEO from "../components/SEO"
const IndexPage = () => {
  
  return (
  <Layout>
    <SEO titlePage="Welcome to Chata" />
    <BannerFinal />
    <SpotlighFinal />
  </Layout>
)}

export default IndexPage
