import React from "react"
import img1 from "../images/gifs/1.gif"
import img2 from "../images/gifs/2.gif"
import img3 from "../images/gifs/3.gif"
import stylesService from "../css/service.module.css"
import Spotlight from "../components/Spotlight"
import {useStaticQuery, graphql} from 'gatsby'
import scrollTo from 'gatsby-plugin-smoothscroll';
import { FaArrowRight } from "react-icons/fa"
import {Icon} from 'antd'
const getSpoths = graphql`
query{
  homeContent:allContentfulHomeContent(sort:{fields: order}){
    edges{
      node{
        title
        invert
        contentful_id
        cardTitle
        order
        cardDescription
        cardIcon
      	fullDescription{
          childContentfulRichText {
            html
          }
        }
      }
    }
  }
  homeBanner:allContentfulBanner{
    edges{
      node{
        title
        subTitle
        text
        titleButton
        url
      }
    }
  }
}
`
const SpotlightFinal = () => {
  const response = useStaticQuery(getSpoths)
  const spothsQuery = response.homeContent.edges
  let imgs = [img1, img2, img3]
  return (
    <div>
        <div className={stylesService.center}>
        {
          
          spothsQuery.map(({ node },index) => {
            let linkFinal = `#content${node.contentful_id}`
            let iconFinal = node.cardIcon
            // <Icon type={iconFinal} className="iconColorWhite"/>
            // <Icon type="iconFinal" theme="twoTone" twoToneColor="ffffff" className="iconColorWhite"/>
            return (
              <article key={index} className={stylesService.service} >
                <Icon type={iconFinal} className="iconColorWhite"/>
                <h4>{node.cardTitle}</h4>
                <p>{node.cardDescription}</p> 
                <button className="btn-white btnEspecial" onClick={() => scrollTo(linkFinal)}>Learn More <FaArrowRight/></button>
              </article>
            )
          })
        }
        </div>
        {spothsQuery.map(({ node },index) => {
          let finalID = `content${node.contentful_id}`
          //let finalText = node.fullDescription.content[0].content[0].value.replace(/\xa0/gi, ' ')
          let finalText = node.fullDescription.childContentfulRichText.html
            return (
              <div key={index}>
                <Spotlight title={node.title} id={finalID} subTitle="" info={finalText} invert={node.invert} img={imgs[index]}  >
                </Spotlight>
              </div>
            )
          })}
    </div>
  )
}
export default SpotlightFinal
// export default test()